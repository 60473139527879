<template>
  <div>
    <h3 class="top_title">签到</h3>
    <el-form
      v-loading="loading"
      label-width="200px"
      :model="dataForm"
      :rules="rules"
      ref="dataForm"
      class="demo-ruleForm"
    >
      <!-- 微信二维码开始时间 -->
      <el-form-item
        id="qrCodeSign"
        label-width="300px"
        label="签到展示微信二维码开始时间"
        prop="qrCodeSign"
        style="margin-bottom: 35px; text-align: left"
      >
        <el-col :span="24">
          <div class="sign-wraper">
            <el-input
              type="number"
              ref="qrCodeSign"
              v-model="dataForm.qrCodeSign"
              placeholder="请输入"
              class="sign-num"
            ></el-input>
            <div class="minute">&nbsp;分钟</div>
            <div>（ 距离每场比赛开始时间 ）</div>
          </div>
        </el-col>
      </el-form-item>
      <!-- 签到开始时间 -->
      <el-form-item
        id="qrSign"
        label-width="300px"
        label="签到开始时间"
        prop="qrSign"
        style="margin-bottom: 35px"
      >
        <el-col :span="24">
          <div class="sign-wraper">
            <el-input
              type="number"
              ref="qrSign"
              v-model="dataForm.qrSign"
              placeholder="请输入"
              class="sign-num"
            ></el-input>
            <div class="minute">&nbsp;分钟</div>
            <div>（ 距离每场比赛开始时间 ）</div>
          </div>
        </el-col>
      </el-form-item>
      <!-- 签到补签开始时间 -->
      <el-form-item
        id="repairSign"
        label-width="300px"
        label="签到补签开始时间"
        prop="repairSign"
        style="margin-bottom: 35px"
      >
        <el-col :span="24">
          <div class="sign-wraper">
            <el-input
              type="number"
              ref="repairSign"
              v-model="dataForm.repairSign"
              placeholder="请输入"
              class="sign-num"
            ></el-input>
            <div class="minute">&nbsp;分钟</div>
            <div>（ 距离每场比赛开始时间 ）</div>
          </div>
        </el-col>
      </el-form-item>
      <!-- 签到展示微信二维码按钮结束时间 -->
      <el-form-item
        id="qrCodeSignEnd"
        label-width="300px"
        label="签到展示微信二维码按钮结束时间"
        prop="qrCodeSignEnd"
        style="margin-bottom: 35px"
      >
        <el-col :span="24">
          <div class="sign-wraper">
            <el-input
              type="number"
              ref="qrCodeSignEnd"
              v-model="dataForm.qrCodeSignEnd"
              placeholder="请输入"
              class="sign-num"
            ></el-input>
            <div class="minute">&nbsp;分钟</div>
            <div>（ 距离每场比赛开始时间 ）</div>
          </div>
        </el-col>
      </el-form-item>
      <!-- 选择不需要签到的赛段 -->
      <el-form-item
        id="signStages"
        label-width="300px"
        label="选择不需要签到的赛段"
        prop="signStages"
        style="margin-bottom: 35px"
      >
        <el-col :span="6">
          <el-select
            v-model="dataForm.signStages"
            value-key="typeId"
            style="width: 200px"
            @change="handleSelectChange"
            multiple
          >
            <el-option
              v-for="(item, index) in optionsMatch"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-form-item>
      <!-- 提交 -->
      <el-form-item style="margin-top: 50px">
        <el-button
          v-loading="loading"
          type="primary"
          @click="submitForm()"
          size="large"
          >提交</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "manager",
  props: {
    view_type: {
      type: String,
    },
  },
  computed: {
    isEdit: function () {
      if (this.view_type == "edit") {
        return true;
      }

      return false;
    },
  },
  data() {
    return {
      optionsMatch: [
        {
          value: "qualifier",
          label: "预选赛",
        },
        {
          value: "regular",
          label: "常规赛",
        },
        {
          value: "final",
          label: "总决赛",
        },
        {
          value: "match_s3_regular",
          label: "门派联赛S3常规赛",
        },
        {
          value: "match_s3_final",
          label: "门派联赛S3总决赛",
        },
      ],

      loading: false,
      type: "s1",
      qrCodeSign: false,
      org_rule: "",
      org_prize: "",
      dataForm: {
        _id: "",
        qrCodeSign: 0,
        qrSign: 0,
        repairSign: 0,
        qrCodeSignEnd: 0,
        signStages: [],
      },
      rules: {
        qrCodeSign: [
          {
            required: true,
            message: "请输入签到展示微信二维码开始时间",
            trigger: ["blur"],
          },
        ],
        qrSign: [
          {
            required: true,
            message: "请输入签到开始时间",
            trigger: ["blur"],
          },
        ],
        repairSign: [
          {
            required: true,
            message: "请输入签到补签开始时间",
            trigger: ["blur"],
          },
        ],
        qrCodeSignEnd: [
          {
            required: true,
            message: "请输入签到展示微信二维码按钮结束时间",
            trigger: ["blur"],
          },
        ],

        // signStages: [
        //   {
        //     required: true,
        //     message: "请选择类型",
        //     trigger: ["blur", "change"],
        //   },
        // ],
      },
      editorOption: {
        placeholder: "请输入详情",
        modules: {
          imageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
        },
      },
      // loading: false,
    };
  },
  mounted() {
    //  this.getMatchNames()
    this.setFormData();
  },
  methods: {
    // 下拉框改为写死
    // async getMatchNames() {
    //   const { data } = await this.$http.match_subject.match_list_stages_names()
    //   this.optionsMatch = data
    // },
    // 拿到选择的内容
     handleSelectChange() {
    const selectedLabels = this.dataForm.signStages.map(value => {
      const match = this.optionsMatch.find(item => item.value === value);
      return match ? match.label : '';
    });
    console.log(selectedLabels);
    // 在这里可以使用selectedLabels进行后续操作
  },
    // 在这里可以对选择的内容进行处理
    async setFormData() {
      let { data } = await this.$http.match_subject.sign_newsDetail({
        _id: this.$route.query._id,
      });
      this.match_data = data;

      if (this.match_data.length != 0) {
        this.match_data = this.match_data[0];
        Object.keys(this.dataForm).forEach((key) => {
          if (this.match_data[key]) {
            this.dataForm[key] = this.match_data[key];
          }
        });
      } else {
        this.$nextTick(() => {
          this.$refs["dataForm"].clearValidate();
        });
        this.dataForm = {
          _id: "",
          qrCodeSign: 0,
          qrSign: 0,
          repairSign: 0,
          qrCodeSignEnd: 0,
        };
      }
    },
    submitForm() {
      this.$refs.dataForm.validate(async (valid, invalidFields) => {
        if (valid) {
          // this.loading = true;
          let d = this.dataForm;
          // 标题不能为纯空格
          let { data, errorCode } = await this.$http.match_subject.saveSignIn(
            d
          );
          if (errorCode != "0000") {
            this.$message.error("保存失败");
            return;
          }
          this.loading = false;
          this.$message({
            type: "success",
            message: "保存成功",
          });
        }
      });
    },
    goBack() {
      this.$router.push(`/operate/notice/index`);
    },
    resetForm() {
      this.$refs.dataForm.resetFields();
    },

    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
};
</script>
<style src="../../editor.css"></style>
<style scoped>
.top_title {
  margin: 21px 0 60px 53px;
  width: 48px;
  height: 35px;
  opacity: 1;
  font-size: 24px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0em;
  color: #3d3d3d;
}
.sign-num {
  width: 120px;
}
.sign-wraper {
  display: flex;
  width: 100%;
}
.minute {
  color: rgb(103, 106, 109);
}
</style>
